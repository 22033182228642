import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Figma"
    img_name="figma_logo.png"
    link="https://www.figma.com/"
    description="Figma is the best design tool for professional UI designers that need a browser-based software with advanced features for interface design."
    twitterHandleTool="@FigmaDesign"
  />
)

export default Tool;


